<template>
  <ion-page>
    <ion-header>
      <ion-toolbar v-if="previewModalMode">
        <ion-title id="title">{{ i18n.$t('report.preview.title') }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">{{ i18n.$t('default_interaction.close') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
      <MainToolbar v-else isSubpage :title="(isEditingExisting) ? i18n.$t('report.update.title') : i18n.$t('report.create.title')" :confirmDiscard="areAnyFieldsModified" />
    </ion-header>
    <ion-content> <!--TODO Fehler anzeigen, wenn Type nicht verfügbar, anstatt einfach nur zurück zu routen. On unrecoverable error keep backdrop alive, never resolve loading to false! -->
      <form ref="headerFormRef">
        <!-- Header for fixed inputs in all reports -->
        <div class="header-container">
          <ion-card class="header-card metadata">
            <ion-card-header>
              <ion-card-title>{{ i18n.$t('report.create.information') }}</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ion-list>
                <FormItem
                  lines="full"
                  :formKey="buildKey(UNCATEGORIZED_METADATA_CATEGORY, undefined, REPORT_PROPERTY_MAPPINGS['timestamp'])"
                  :formKeySeparator="CATEGORY_SEPARATOR"
                  :display_name="i18n.$t('report.create.examination_time')"
                  type="datetime"
                  :modelValue="getReportProperty(REPORT_PROPERTY_MAPPINGS['timestamp'])"
                  :convertPresetValue="now"
                  @update:convertPresetValue="(value) => setPresetReportProperty(REPORT_PROPERTY_MAPPINGS['timestamp'], value)"
                  @update:modelValue="(value) => setReportProperty(REPORT_PROPERTY_MAPPINGS['timestamp'], value)"
                  @update:modified="(newModifiedStatus) => setFieldModified(UNCATEGORIZED_METADATA_CATEGORY, UNCATEGORIZED_METADATA_CATEGORY, undefined, REPORT_PROPERTY_MAPPINGS['timestamp'], newModifiedStatus)"
                  @update:invalid="(newInvalidStatus) => setFieldInvalid(UNCATEGORIZED_METADATA_CATEGORY, UNCATEGORIZED_METADATA_CATEGORY, undefined, REPORT_PROPERTY_MAPPINGS['timestamp'], newInvalidStatus)"
                  @update:preset="(newPresetStatus) => setFieldPreset(UNCATEGORIZED_METADATA_CATEGORY, UNCATEGORIZED_METADATA_CATEGORY, undefined, REPORT_PROPERTY_MAPPINGS['timestamp'], newPresetStatus)"
                  :max="tomorrow"
                  returnRawValue
                  :icon="faCalendarDay"
                  iconIsFontAwesome
                  required >
                </FormItem>
                <ion-item :lines="isVeterinarian ? 'full' : 'none'" :class="['animal-select', selectedHorseStatusStyle]"> <!--TODO Make focusable via tab in Safari -->
                  <ion-label position="stacked">{{ i18n.$t('report.associated_horse') }}</ion-label>
                  <AnimalSelect
                    :selectedAnimalId="getReportProperty(REPORT_PROPERTY_MAPPINGS['animal'], mapHorseToPersonalHorseInfoId)"
                    @update:selectedAnimalId="(newAnimalId) => setReportProperty(REPORT_PROPERTY_MAPPINGS['animal'], newAnimalId)"
                    :availableAnimals="availableHorses"
                    showAvatar
                    showAdditionalInfo
                    required >
                  </AnimalSelect>
                </ion-item>

                <FormItem
                  v-if="isVeterinarian"
                  lines="none"
                  :formKey="buildKey(UNCATEGORIZED_METADATA_CATEGORY, undefined, REPORT_PROPERTY_MAPPINGS['location'])"
                  :formKeySeparator="CATEGORY_SEPARATOR"
                  :display_name="i18n.$t('report.location.name')"
                  type="text"
                  :modelValue="getReportProperty(REPORT_PROPERTY_MAPPINGS['location'])"
                  :convertPresetValue="locationPresetSetting"
                  @update:convertPresetValue="(value) => setPresetReportProperty(REPORT_PROPERTY_MAPPINGS['location'], value)"
                  @update:modelValue="(value) => setReportProperty(REPORT_PROPERTY_MAPPINGS['location'], value)"
                  @update:modified="(newModifiedStatus) => setFieldModified(UNCATEGORIZED_METADATA_CATEGORY, UNCATEGORIZED_METADATA_CATEGORY, undefined, REPORT_PROPERTY_MAPPINGS['location'], newModifiedStatus)"
                  @update:invalid="(newInvalidStatus) => setFieldInvalid(UNCATEGORIZED_METADATA_CATEGORY, UNCATEGORIZED_METADATA_CATEGORY, undefined, REPORT_PROPERTY_MAPPINGS['location'], newInvalidStatus)"
                  @update:preset="(newPresetStatus) => setFieldPreset(UNCATEGORIZED_METADATA_CATEGORY, UNCATEGORIZED_METADATA_CATEGORY, undefined, REPORT_PROPERTY_MAPPINGS['location'], newPresetStatus)"
                  :available_values="locationValues"
                  returnRawValue
                  :icon="faHouseMedicalFlag"
                  iconIsFontAwesome
                  :selectValuesSeparately="isWidePhoneScreen"
                  :seperateSelectValuesLimit="3"
                  required >
                </FormItem>
              </ion-list>
            </ion-card-content>
          </ion-card>

          <!--<ion-card class="header-card additional">
            <ion-card-header>
              <ion-card-title>{{ i18n.$t('report.create.notes') }}</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <FormItem
                lines="full"
                :formKey="buildKey(UNCATEGORIZED_METADATA_CATEGORY, undefined, 'voice_note')"
                :formKeySeparator="CATEGORY_SEPARATOR"
                :display_name="i18n.$t('report.voice_note')"
                class="voice-note-input"
                type="audio"
                :modelValue="getReportMetadata('voice_note')"
                @update:convertPresetValue="(value) => setPresetReportMetadata('voice_note', value)"
                @update:modelValue="(value) => setReportMetadata('voice_note', value)"
                @update:modified="(newModifiedStatus) => setFieldModified(UNCATEGORIZED_METADATA_CATEGORY, UNCATEGORIZED_METADATA_CATEGORY, undefined, 'voice_note', newModifiedStatus)"
                @update:invalid="(newInvalidStatus) => setFieldInvalid(UNCATEGORIZED_METADATA_CATEGORY, UNCATEGORIZED_METADATA_CATEGORY, undefined, 'voice_note', newInvalidStatus)"
                @update:preset="(newPresetStatus) => setFieldPreset(UNCATEGORIZED_METADATA_CATEGORY, UNCATEGORIZED_METADATA_CATEGORY, undefined, 'voice_note', newPresetStatus)"
                :icon="faVoicemail"
                iconIsFontAwesome
                returnRawValue >
              </FormItem>
              <FormItem
                lines="none"
                :formKey="buildKey(UNCATEGORIZED_METADATA_CATEGORY, undefined, 'text_note')"
                :formKeySeparator="CATEGORY_SEPARATOR"
                :display_name="i18n.$t('report.text_note')"
                type="text"
                :modelValue="getReportMetadata('text_note')"
                @update:convertPresetValue="(value) => setPresetReportMetadata('text_note', value)"
                @update:modelValue="(value) => setReportMetadata('text_note', value)"
                @update:modified="(newModifiedStatus) => setFieldModified(UNCATEGORIZED_METADATA_CATEGORY, UNCATEGORIZED_METADATA_CATEGORY, undefined, 'text_note', newModifiedStatus)"
                @update:invalid="(newInvalidStatus) => setFieldInvalid(UNCATEGORIZED_METADATA_CATEGORY, UNCATEGORIZED_METADATA_CATEGORY, undefined, 'text_note', newInvalidStatus)"
                @update:preset="(newPresetStatus) => setFieldPreset(UNCATEGORIZED_METADATA_CATEGORY, UNCATEGORIZED_METADATA_CATEGORY, undefined, 'text_note', newPresetStatus)"
                :icon="faClipboardList"
                iconIsFontAwesome
                returnRawValue
                :allow_custom_values="true" >
              </FormItem>
            </ion-card-content>
          </ion-card>-->
        </div>
      </form>

      <!-- Report Fields start here -->
      <ion-card class="protocol-selection-header">
        <ion-card-header >
          <ion-card-subtitle>{{ i18n.$t('report.create.protocol_selection') }}</ion-card-subtitle>
        </ion-card-header>
      </ion-card>
      <div class="protocol-selection-overflow-bounds">
        <ion-card class="protocol-selection-container">
          <LongTabBar
            class="protocol-selection"
            :title="i18n.$t('report.create.protocol_selection')"
            :selectableOptions="availableReportTypes" 
            v-model:selected="currentReportType"
            :dangerIndicatorForKeys="invalidReportTypes"
            :successIndicatorForKeys="modifiedAndValidReportTypes"
            :primaryIndicatorForKeys="presetReportTypes">
          </LongTabBar> <!-- TODO Use the button in the end to also show an option for selecting "Add protocol" for a popup with more protocols, if enabled -->
        </ion-card>
      </div>
      <ion-card class="protocol-content-container">
        <ion-card-content class="protocol-content">
          <form 
            v-for="(reportType, reportTypeIndex) in reportTypes"
            :key="reportTypeIndex"
            :ref="(formElementRef) => setReportTypeFormRef(reportType.id, formElementRef)"
            :class="['protocol-form', (reportType.id != currentReportType) ? 'protocol-hidden' : undefined]"
            > <!-- hidden protocols and items should always be at least display: none, never unloaded, so they can be checked for invalidity! -->
            <ion-list class="protocol-content-list">
              <CollapsibleList v-for="([categoryName, category], categoryNameIndex) in getSortedEntriesBySortOrder(reportType.type.definition)" 
                class="category-header"
                :key="categoryNameIndex" 
                :open="categoryName == UNCATEGORIZED_CATEGORY"
                :showHeader="categoryName !== UNCATEGORIZED_CATEGORY"
                :title="getLocalizedReportString(categoryName)"
                :primaryIndicator="getPresetEntryCount(reportType.id, [categoryName])"
                :successIndicator="getModifiedAndValidEntryCount(reportType.id, [categoryName])"
                :dangerIndicator="getInvalidEntryCount(reportType.id, [categoryName])"
                openOnInvalidEvent > <!--TODO Test what happens, if decimal, text or number has no available values and no custom values allowed -->
                
                <FormItem v-for="([itemName, item], itemNameIndex) in getSortedEntriesBySortOrder(category.items)" :key="itemNameIndex"
                  class="form-item"
                  lines="full"
                  :formKey="buildKey(categoryName, undefined, itemName)"
                  :formKeySeparator="CATEGORY_SEPARATOR"
                  :display_name="getLocalizedReportString(itemName)"
                  :type="item.type"
                  :modelValue="getReportField(reportType.id, categoryName, undefined, itemName)"
                  :convertPresetValue="item.preset_value"
                  @update:convertPresetValue="(value) => setPresetReportField(reportType.id, categoryName, undefined, itemName, value)"
                  @update:modelValue="(value) => setReportField(reportType.id, categoryName, undefined, itemName, value)"
                  @update:modified="(newModifiedStatus) => setFieldModified(reportType.id, categoryName, undefined, itemName, newModifiedStatus)"
                  @update:invalid="(newInvalidStatus) => setFieldInvalid(reportType.id, categoryName, undefined, itemName, newInvalidStatus)"
                  @update:preset="(newPresetStatus) => setFieldPreset(reportType.id, categoryName, undefined, itemName, newPresetStatus)"
                  :pro_only="item.pro_only"
                  :allow_custom_values="item.allow_custom_values"
                  :allow_multiple_values="item.allow_multiple_values"
                  :unit="getLocalizedReportString(item.unit)"
                  :available_values="getLocalizedReportStringArray(item.available_values)"
                  :selectValuesSeparately="isBigScreen"
                  :custom_placeholder="item.custom_placeholder"
                  :required="item.required"
                  :capture_options="item.capture_options" >
                </FormItem> <!-- TODO Never load any presets, when it is being edited! -->
                <div v-for="([subCategoryName, subCategory], subCategoryNameIndex) in getSortedEntriesBySortOrder(category.sub_categories)" :key="subCategoryNameIndex">
                  <RepeatedDividerSelection 
                    cssClass="subcategory-divider"
                    :title="getLocalizedReportString(subCategoryName)"
                    :repeatable="subCategory.repeatable"
                    :integerIndex="subCategory['integer_index']"
                    :indexValues="subCategory['index_values']"
                    :formKey="buildKey(categoryName, subCategoryName, 'INDEX_SELECT')"
                    :formKeySeparator="CATEGORY_SEPARATOR"
                    :presetUsedIndexValues="getPresetRepeatedSubCategories(reportType.id, categoryName, subCategoryName)"
                    @update:invalid="(invalidityIndexId, newInvalidStatus) => setFieldInvalid(reportType.id, categoryName, subCategoryName, 'INDEX_SELECT_INVALIDITY_CHECK', newInvalidStatus, invalidityIndexId)"
                    @removedIndex="(indizes) => removeIndizesFromSubCategory(reportType.id, categoryName, subCategoryName, indizes)"
                    :invalidIfEmpty="getModifiedOrPresetEntries(reportType.id, [categoryName, subCategoryName])"> <!-- All indizes that have been filled out are considered invalid if the index does not have a value set yet -->
                    <template v-slot:default="{ indexId, indexValue }">
                      <FormItem v-for="([itemName, item], itemNameIndex) in getSortedEntriesBySortOrder(subCategory.items)" :key="`${itemNameIndex}-${indexId}`"
                        class="form-item"
                        lines="full"
                        :formKey="buildKey(categoryName, subCategoryName, itemName)"
                        :indexValue="indexValue"
                        :formKeySeparator="CATEGORY_SEPARATOR"
                        :display_name="getLocalizedReportString(itemName)"
                        :type="item.type"
                        :modelValue="getReportField(reportType.id, categoryName, subCategoryName, itemName, indexId)"
                        :convertPresetValue="item.preset_value"
                        @update:convertPresetValue="(value) => setPresetReportField(reportType.id, categoryName, subCategoryName, itemName, value, indexId)"
                        @update:modelValue="(value) => setReportField(reportType.id, categoryName, subCategoryName, itemName, value, indexId)"
                        @update:modified="(newModifiedStatus) => setFieldModified(reportType.id, categoryName, subCategoryName, itemName, newModifiedStatus, indexId)"
                        @update:invalid="(newInvalidStatus) => setFieldInvalid(reportType.id, categoryName, subCategoryName, itemName, newInvalidStatus, indexId)"
                        @update:preset="(newPresetStatus) => setFieldPreset(reportType.id, categoryName, subCategoryName, itemName, newPresetStatus, indexId)"
                        :pro_only="item.pro_only"
                        :allow_custom_values="item.allow_custom_values"
                        :allow_multiple_values="item.allow_multiple_values"
                        :unit="getLocalizedReportString(item.unit)"
                        :available_values="getLocalizedReportStringArray(item.available_values)"
                        :selectValuesSeparately="isBigScreen"
                        :custom_placeholder="item.custom_placeholder"
                        :required="item.required"
                        :capture_options="item.capture_options" >
                      </FormItem>
                    </template>
                  </RepeatedDividerSelection>
                </div>
                <!-- Additional input for fields we did not think of -->
                <div v-if="categoryName !== UNCATEGORIZED_CATEGORY" :class="isAdditionalCommentEnabled(reportType.id, categoryName) ? 'expandable-additional-comments subcategory enabled' : 'expandable-additional-comments subcategory'">
                  <ion-item-divider class="subcategory-divider">
                    <ion-label>
                      {{ i18n.$t('report.create.additional_comments') }}
                    </ion-label>
                    <ion-button class="add-comment-button" slot="end" shape="round" fill="clear" @click="toggleAdditionalComment(reportType.id, categoryName)" @keydown="handleSpacebar($event, ()=>$event.target.click())">
                      <ion-icon slot="icon-only" :icon="addCircleOutline">
                      </ion-icon>
                    </ion-button>
                  </ion-item-divider>
                  <FormItem
                    class="form-item"
                    lines="full"
                    :formKey="buildKey(categoryName, undefined, ADDITIONAL_COMMENT_FIELD)"
                    :formKeySeparator="CATEGORY_SEPARATOR"
                    :display_name="i18n.$t('report.create.free_form_input')"
                    type="text"
                    :modelValue="getReportField(reportType.id, categoryName, undefined, ADDITIONAL_COMMENT_FIELD)"
                    @update:convertPresetValue="(value) => setPresetReportField(reportType.id, categoryName, undefined, ADDITIONAL_COMMENT_FIELD, value)"
                    @update:modelValue="(value) => setReportField(reportType.id, categoryName, undefined, ADDITIONAL_COMMENT_FIELD, value)"
                    @update:modified="(newModifiedStatus) => setFieldModified(reportType.id, categoryName, undefined, ADDITIONAL_COMMENT_FIELD, newModifiedStatus)"
                    @update:invalid="(newInvalidStatus) => setFieldInvalid(reportType.id, categoryName, undefined, ADDITIONAL_COMMENT_FIELD, newInvalidStatus)"
                    @update:preset="(newPresetStatus) => setFieldPreset(reportType.id, categoryName, undefined, ADDITIONAL_COMMENT_FIELD, newPresetStatus)"
                    :allow_custom_values="true" >
                  </FormItem>
                </div>
              </CollapsibleList>

              <!-- Additional input after all categories for additional_comments as an uncategorized item -->
              <div :class="isAdditionalCommentEnabled(reportType.id, UNCATEGORIZED_CATEGORY) ? 'expandable-additional-comments enabled' : 'expandable-additional-comments'">
                <ion-item-divider class="additional-comment-report-divider"> 
                  <ion-label>
                    {{ i18n.$t('report.create.additional_comments_report') }}
                  </ion-label>
                  <ion-button class="add-comment-button" slot="end" shape="round" fill="clear" @click="toggleAdditionalComment(reportType.id, UNCATEGORIZED_CATEGORY)" @keydown="handleSpacebar($event, ()=>$event.target.click())">
                    <ion-icon slot="icon-only" :icon="addCircleOutline">
                    </ion-icon>
                  </ion-button>
                </ion-item-divider>
                <FormItem
                  class="form-item additional-comment-report"
                  lines="full"
                  :formKey="buildKey(UNCATEGORIZED_CATEGORY, undefined, ADDITIONAL_COMMENT_FIELD)"
                  :formKeySeparator="CATEGORY_SEPARATOR"
                  :display_name="i18n.$t('report.create.free_form_input')"
                  type="text"
                  :modelValue="getReportField(reportType.id, UNCATEGORIZED_CATEGORY, undefined, ADDITIONAL_COMMENT_FIELD)"
                  @update:convertPresetValue="(value) => setPresetReportField(reportType.id, UNCATEGORIZED_CATEGORY, undefined, ADDITIONAL_COMMENT_FIELD, value)"
                  @update:modelValue="(value) => setReportField(reportType.id, UNCATEGORIZED_CATEGORY, undefined, ADDITIONAL_COMMENT_FIELD, value)"
                  @update:modified="(newModifiedStatus) => setFieldModified(reportType.id, UNCATEGORIZED_CATEGORY, undefined, ADDITIONAL_COMMENT_FIELD, newModifiedStatus)"
                  @update:invalid="(newInvalidStatus) => setFieldInvalid(reportType.id, UNCATEGORIZED_CATEGORY, undefined, ADDITIONAL_COMMENT_FIELD, newInvalidStatus)"
                  @update:preset="(newPresetStatus) => setFieldPreset(reportType.id, UNCATEGORIZED_CATEGORY, undefined, ADDITIONAL_COMMENT_FIELD, newPresetStatus)"
                  :allow_custom_values="true" > <!-- TODO Rename to be only for this protocol!! -->
                </FormItem>
              </div>
            </ion-list>
          </form>
        </ion-card-content>
      </ion-card>

      <!-- Allows scrolling beyond the list to interact with items without the FAB blocking interaction -->
      <div id="over-scroll"></div>
    </ion-content>

    <ion-backdrop v-if="currentlySubmitting || loading" :class="currentlySubmitting ? 'block-all' : undefined" visible="true" tappable="false"></ion-backdrop>
    <div class="loading-spinner" v-if="loading">
      <ion-spinner></ion-spinner>
    </div>

    <ion-fab vertical="bottom" horizontal="end" slot="fixed" v-if="!previewModalMode">
      <ion-fab-button :disabled="(!enoughDataHasBeenEntered) || currentlySubmitting" ref="saveButton" color="primary" @click="submitReport()" :class="['save-button', (currentlySubmitting ? 'submitting' : undefined)]">
        <ion-spinner v-if="currentlySubmitting"></ion-spinner>
        <ion-icon v-else :icon="save"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-page> 
</template>

<script>
import { IonPage, IonHeader, IonContent, IonLabel, IonList, IonItem, IonIcon, IonItemDivider, IonFab, IonFabButton, IonButton, IonSpinner, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonBackdrop, IonToolbar, IonTitle, IonButtons, /* toastController, */ modalController} from '@ionic/vue';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { faCalendarDay, faHouseMedicalFlag, faClipboardList, faVoicemail } from '@fortawesome/free-solid-svg-icons';
import { chevronForward, save, addCircleOutline } from 'ionicons/icons';

import MainToolbar from '@/components/MainToolbar.vue';
import AnimalSelect from '@/components/AnimalSelect.vue';
import FormItem from '@/components/forms/FormItem.vue';
import CollapsibleList from '@/components/CollapsibleList.vue';
import LongTabBar from '@/components/LongTabBar.vue';
import RepeatedDividerSelection from '@/components/forms/RepeatedDividerSelection.vue';

import _ from 'lodash';

import { useI18n } from "@/utils/i18n";

import { useDayjs } from '@/utils/dayjs';

import { handleSpacebar } from '@/utils/interaction';

import { REPORT_PROPERTY_MAPPINGS, METADATA_PROPERTY, FIELDS_PROPERTY, CATEGORY_SEPARATOR, UNCATEGORIZED_CATEGORY, UNCATEGORIZED_METADATA_CATEGORY, ADDITIONAL_COMMENT_FIELD, UNKOWN_ANIMAL_ID, buildKey, reportFieldsToHierarchicalObject, typeDefinitionToHierarchicalObject, flattenHierarchicalObject, getSortedEntriesBySortOrder, findFirstKeyDeep } from '@/utils/report';

import { apiErrorToast, localErrorToast } from '@/utils/error';
//TODO When multiple languages get supported, check that the untranslated version is always sent!
export default  {
  name: 'CreateReport',
  components: { IonHeader, IonContent, IonPage, MainToolbar, IonLabel, IonList, IonItem, IonIcon, IonItemDivider, IonFab, IonFabButton, IonButton, IonSpinner, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonBackdrop, IonToolbar, IonTitle, IonButtons, AnimalSelect, FormItem, CollapsibleList, LongTabBar, RepeatedDividerSelection },
  props: {
    types: String, //List as a string with a separator of types (might be just one, then no separator is used)
    id: String,
    connection_id: String,
    animalId: {
      type: String,
      default: null
    },
    preLoadedTypes: Object, //Object of types that have been supplied and don't need to be loaded! Mainly used for preview!
    previewModalMode: {
      type: Boolean,
      default: false
    }
  },
  setup(props){
    const i18n = useI18n();

    const store = useStore();

    const isVeterinarian = computed(() => {
      return store.getters['auth/isVeterinarian'];
    });

    const locationValues = computed(() => _.map(store.getters['reports/getLocationValues'], (value) => {
      return { value, display: i18n.$t(`report.location.${value}`) };
    }));
    
    const router = useRouter();

    const { dayjs } = useDayjs();

    const headerFormRef = ref(null);

    const saveButton = ref(null);

    const reportEntryRefs = ref({});

    const loadingReports = ref(false);

    const loadingReportTypes = ref(false);

    const loading = computed(() => (loadingReports.value || loadingReportTypes.value));

    //Saves for every type ID the according report ID, if it has been loaded
    const loadedReportIDs = ref({});

    //Store properties and metadata as separate objects
    //Properties have to be equal for all reports
    const loadedReportProperties = ref({});
    //Metadata gets saved separately
    const loadedReportMetadata = ref({});

    //Object with each report saved under the key of its report type (Only contains the report fields!)
    const loadedReportsFields = ref({}); //loadedReportsFields is the indication, if we are in edit mode! If any are present, we are editing!
    //TODO Maybe allow multiple of the same report type and store it differently!

    //The modifications are saved separately for each part of the reports
    const modifiedReportProperties = ref({});
    const modifiedReportMetadata = ref({});
    const modifiedReportsFields = ref({});

    //And all the presets are saved when they are accessed so we can use them for generating the report
    const presetReportProperties = ref({});
    const presetReportMetadata = ref({});
    const presetReportsFields = ref({});

    const TYPE_SEPARATOR = ',';

    const reportTypeIds = computed(() => {
      if (props.types) { //FIXME Doesn't refresh when going back and selecting something else! Needs to enable addition of reportTypeIDs
        return _.split(props.types, TYPE_SEPARATOR);
      } else if (loadedReportsFields.value != null) {
        return _.filter(Object.keys(loadedReportsFields.value), (typeKey) => typeKey != 'null' && typeKey != 'undefined');
      } else {
        return null;
      }
    });

    const enabledAdditionalComments = ref({});

    //Set the additional_comments to be visible when they have a value
    watch(loadedReportsFields, (newReports) => {
      for (let [reportTypeID, report] of Object.entries(newReports)) {
        if (report != null) {
          for (let [categoryName, category] of Object.entries(report)) {
            //Look if this category has additional_comments set with a value somewhere
            let additionalCommentObject = findFirstKeyDeep(category, ADDITIONAL_COMMENT_FIELD);
            if (_.get(additionalCommentObject, ['value', 'value']) != null) {
              _.setWith(enabledAdditionalComments.value, [reportTypeID, categoryName], true, Object);
            }
          }
        }
      }
    });

    const now = dayjs.utc().toISOString();

    const tomorrow = dayjs.utc().add(1, 'day').toISOString();

    //Saves internal partial types that were sent with the report as a fallback!
    const loadedReportTypes = ref({});

    const reportTypes = ref([]);

    //Map report types to just include key and descriptor in the correct order
    const availableReportTypes = computed(() => {
      if (!Array.isArray(reportTypes.value)) return [];
      return _.map(reportTypes.value, (typeObject) => {
        return {
          key: typeObject.id,
          display: typeObject.type.descriptor //TODO Can be localized here!
        }
      });
    });

    //Holds all the forms for each report type with its id as the key
    const reportTypeFormRefs = ref({});

    const setReportTypeFormRef = function(reportTypeId, formRef){
      if (formRef == null) delete reportTypeFormRefs.value[reportTypeId];
      reportTypeFormRefs.value[reportTypeId] = formRef;
    }

    //Returns array of report type IDs in the correct order
    const loadedReportTypesIDs = computed(() => {
      return _.map(reportTypes.value, 'id');
    });

    const currentReportType = ref(null);

    //If available report types change, select the first report type if it is valid
    watch(availableReportTypes, (newReportTypes) => {
      if (Array.isArray(newReportTypes) && newReportTypes.length > 0) {
        currentReportType.value = newReportTypes[0].key;
      }
    });

    const reportTypesByKey = computed(() => {
      if (!Array.isArray(reportTypes.value)) return {};
      return _.keyBy(reportTypes.value, 'id');
    });

    const currentlySubmitting = ref(false);
  
    //Contains all available animals and an additional empty entry for an animal that is not contained in the available ones, but loaded
    const availableHorses = computed(() => {
      //Make a copy of all available animals
      let animals = {...store.getters['horses/getHorseInfos']};
      //Get the preset horse (or from the properties) to check if it is included
      let presetHorseId = mapHorseToPersonalHorseInfoId.value(_.get(loadedReportProperties.value, [REPORT_PROPERTY_MAPPINGS['animal']], undefined));
      //If the preset one is not included in the available ones, add it as empty entry!
      if (!(presetHorseId in animals)) {
        animals[presetHorseId] = {};
      }
      return animals;
    });

    const computedAnimalId = computed(() => {
      if (props.animalId != null) return props.animalId;
      return store.getters['horses/getSelectedHorse'] || undefined; 
    });

    //Save all horses that were mapped to reverse the mapping when saving again to preserve the used horse //TODO If it is not the own horse, change anyway!
    const personalHorseReverseMapping = ref({});

    const mapHorseToPersonalHorseInfoId = computed(() => {
      const givenPresetHorseId = computedAnimalId.value;
      return (horseId) => {
        //Only continue if it is a valid ID
        if (horseId == null) {
          //If there is a horse given in the props, return that horse as fallback
          if (givenPresetHorseId != null) return givenPresetHorseId;
          //Otherwise return the given horseId (null or undefined)
          return horseId;
        }

        //Try to map horse to personal horse info ID
        let personalHorseInfo = store.getters['horses/getHorseById'](horseId);
        let personalId = UNKOWN_ANIMAL_ID; //Fallback if personal horse info is not found - Uses a placeholder to add it to the select and just reverse it when saving again!
        if (personalHorseInfo != null && personalHorseInfo.id != null) { //If a valid personal horse info entry is found, use it to get the ID
          personalId = personalHorseInfo.id;
        }

        personalHorseReverseMapping.value[personalId] = horseId;

        return personalId;
      }
    });

    const selectedHorseStatusStyle = computed(() => {
      let modifiedHorse = _.get(modifiedReportProperties.value, [REPORT_PROPERTY_MAPPINGS['animal']], undefined);
      let presetHorse = mapHorseToPersonalHorseInfoId.value(_.get(loadedReportProperties.value, [REPORT_PROPERTY_MAPPINGS['animal']], undefined));

      //Invalidity is always the state that overrides all others, invalid when empty
      if (modifiedHorse == null) {
        return 'invalid';
      } 
      //If it is not invalid (empty) and different from preset, it is modified
      else if (modifiedHorse != presetHorse) {
        return 'modified';
      } 
      //Otherwise it is not invalid, because it has a value and the value matches preset (if preset is null this is never reached, because modified can never be null and the previous one match!)
      else {
        return 'preset';
      }
    });

    //Contains in the hierarchy of reportTypeID, category and sub category an array of indizes used each. Can contain duplicates!
    const presetRepeatedSubCategories = computed(() => {
      return _.mapValues(loadedReportsFields.value, (categories) => {
        return _.mapValues(categories, (subCategories) => {
          return _.mapValues(subCategories, (indizes) => {
            return Object.keys(indizes);
          });
        });
      });
    });

    const getPresetRepeatedSubCategories = computed(() => (reportTypeID, categoryDescriptor, subCategoryDescriptor) => {
      let subCategoryPath = [reportTypeID, categoryDescriptor, subCategoryDescriptor];

      //Return the used indizes for this sub category if it can be found or an empty array
      let presetIndizes = _.get(presetRepeatedSubCategories.value, subCategoryPath, []);
      //Remove duplicates using non-strict equality to compare strings and integers equally
      presetIndizes = _.uniqWith(presetIndizes, (a, b) => a == b);
      //Map it so that both value and id are the same in an array of objects!
      return _.map(presetIndizes, (indexValue) => ({ id: indexValue, value: indexValue }));
    });

    //Returns the property, if it is set, else fallbackDefault - Maps the value of the loaded report if requested, otherwise just returns the value.
    const getReportProperty = computed(() => (propertyName, mapLoadedReportValue = ((value) => value)) => {
      //Try to get modified value if it exists, return it if not undefined
      let modifiedValue = _.get(modifiedReportProperties.value, [propertyName], undefined);
      if (modifiedValue !== undefined) return modifiedValue;
      //Else get loaded one if it exists, return it if not undefined
      let loadedValue = _.get(loadedReportProperties.value, [propertyName], undefined);
      if (loadedValue !== undefined) return mapLoadedReportValue(loadedValue);
      //Else take preset value or undefined if it can't be found and map it
      return mapLoadedReportValue(_.get(presetReportProperties.value, [propertyName], undefined));
    });

    //Returns the metadata, if it is set, else fallbackDefault - Maps the value of the loaded report if requested, otherwise just returns the value.
    const getReportMetadata = computed(() => (propertyName, mapLoadedReportValue = ((value) => value)) => {
      //Try to get modified value if it exists, return it if not undefined
      let modifiedValue = _.get(modifiedReportMetadata.value, [propertyName], undefined);
      if (modifiedValue !== undefined) return modifiedValue;
      //Else get loaded one if it exists, return it if not undefined
      let loadedValue = _.get(loadedReportMetadata.value, [propertyName], undefined);
      if (loadedValue !== undefined) return mapLoadedReportValue(loadedValue);
      //Else take preset value or undefined if it can't be found and map it
      return mapLoadedReportValue(_.get(presetReportMetadata.value, [propertyName], undefined));
    });

    //Returns the field value, if it exists, else fallbackDefault - Maps the value of the loaded report if requested, otherwise just returns the value.
    const getReportField = computed(() => (reportTypeID, categoryDescriptor, subCategoryDescriptor, name, index = null, mapLoadedReportValue = ((value) => value)) => {
      let fieldPath = [reportTypeID, categoryDescriptor, subCategoryDescriptor, index, name];

      //Try to get modified value if it exists, return it if not undefined
      let modifiedValue = _.get(modifiedReportsFields.value, fieldPath, undefined);
      if (modifiedValue !== undefined) return modifiedValue;
      //Else get loaded one if it exists, return it if not undefined
      let loadedValue = _.get(loadedReportsFields.value, fieldPath, undefined);
      if (loadedValue !== undefined) return mapLoadedReportValue(loadedValue);
      //Else take preset value or undefined if it can't be found and map it
      return mapLoadedReportValue(_.get(presetReportsFields.value, fieldPath, undefined));
    });

    //Sets the property, always use objects to construct the path. Removes present value if value is undefined. 
    const setReportProperty = function(propertyName, value) {
      if (value === undefined) return _.unset(modifiedReportProperties.value, [propertyName]); //TODO Check how preset and removed files behave!
      return _.setWith(modifiedReportProperties.value, [propertyName], value, Object);
    }

    //Sets the metadata, always use objects to construct the path. Removes present value if value is undefined. 
    const setReportMetadata = function(propertyName, value) {
      if (value === undefined) return _.unset(modifiedReportMetadata.value, [propertyName]);
      return _.setWith(modifiedReportMetadata.value, [propertyName], value, Object);
    }

    //Sets the field value, always use objects to construct the path. Removes present value if value is undefined.
    const setReportField = function(reportTypeID, categoryDescriptor, subCategoryDescriptor, name, value, index = null) {
      let fieldPath = [reportTypeID, categoryDescriptor, subCategoryDescriptor, index, name];

      if (value === undefined) return _.unset(modifiedReportsFields.value, fieldPath);
      return _.setWith(modifiedReportsFields.value, fieldPath, value, Object);
    }

    //Sets the property, always use objects to construct the path. Removes present value if value is undefined. 
    const setPresetReportProperty = function(propertyName, value) {
      if (value === undefined) return _.unset(presetReportProperties.value, [propertyName]); //TODO Check how preset and removed files behave!
      return _.setWith(presetReportProperties.value, [propertyName], value, Object);
    }

    //Sets the metadata, always use objects to construct the path. Removes present value if value is undefined. 
    const setPresetReportMetadata = function(propertyName, value) {
      if (value === undefined) return _.unset(presetReportMetadata.value, [propertyName]);
      return _.setWith(presetReportMetadata.value, [propertyName], value, Object);
    }

    //Sets the field value, always use objects to construct the path. Removes present value if value is undefined.
    const setPresetReportField = function(reportTypeID, categoryDescriptor, subCategoryDescriptor, name, value, index = null) {
      let fieldPath = [reportTypeID, categoryDescriptor, subCategoryDescriptor, index, name];

      if (value === undefined) return _.unset(presetReportsFields.value, fieldPath);
      return _.setWith(presetReportsFields.value, fieldPath, value, Object);
    }

    const removeIndizesFromSubCategory = function(reportTypeID, categoryDescriptor, subCategoryDescriptor, indizes){
      let subCategoryPath = [reportTypeID, categoryDescriptor, subCategoryDescriptor];
      //For each index remove it from the object path of the current sub category by setting it to null
      for (let index of indizes) {
        let indexPath = _.concat(subCategoryPath, [index.id]);
        //If this report is being edited, set fields to null and re-set the whole object
        if (reportTypeID in loadedReportsFields.value) {
          let currentValues = _.get(modifiedReportsFields.value, indexPath);
          //Set every value in the object to null to delete from the server on editing
          let nullifiedValues = _.mapValues(currentValues, (currentComponentValue) => {
            return {...currentComponentValue, 'value': null}
          });
          _.setWith(modifiedReportsFields.value, indexPath, nullifiedValues, Object);
        } else { //Otherwise, it is a new report and we can just unset the whole index
          _.unset(modifiedReportsFields.value, indexPath);
        }
      }
    }

    const getLocalizedReportString = function(text) { //TODO Move these and the ones in ViewReport maybe to vuex store
      return text; //TODO Use translation relation to translate each string here.
    }

    const getLocalizedReportStringArray = function(textArray) {
      if (Array.isArray(textArray)){
        return textArray.map((text) => {
          return {
            value: text,
            display: getLocalizedReportString(text)
          }
        });
      } else {
        return null;
      }
    }

    const isAdditionalCommentEnabled = computed(() => {
      return function(reportTypeID, categoryName) {
        //Try to get from the status object, default is false
        return _.get(enabledAdditionalComments.value, [reportTypeID, categoryName], false);
      };
    });

    const toggleAdditionalComment = computed(() => {
      return function(reportTypeID, categoryName) {
        //Get the current display status (default false) and set the inverse
        let currentDisplayStatus = isAdditionalCommentEnabled.value(reportTypeID, categoryName);
        _.setWith(enabledAdditionalComments.value, [reportTypeID, categoryName], (!currentDisplayStatus), Object);
      }
    });

    //Save status for each type as a key with an object for all the types and categories
    const fieldStatuses = ref({
      'preset': {},
      'modified': {},
      'invalid': {}
    });

    //Helper function to unify all the modifications
    const setFieldStatus = function(statusKey, reportTypeID, categoryDescriptor, subCategoryDescriptor = '', itemDescriptor, newStatus, index = 0) {
      let statusPath = [statusKey, reportTypeID, categoryDescriptor, subCategoryDescriptor, index, itemDescriptor];

      //If the status is not recorded yet, but newStatus is false, do not record as implicit default is false for unchanged status values
      if ((!(_.has(fieldStatuses.value, statusPath))) && newStatus !== true) {
        return; //Prevents unnecessary changes initially that cause all the watchers and computed to be evaluated too many times!
      }

      //Otherwise it is true or already recorded, so we set the new status (creating path if it doesn't exist yet! And always as Objects!!)
      _.setWith(fieldStatuses.value, statusPath, newStatus, Object);
    }

    //Save invalidity status per category for each report type with a key of subCategory and item
    const setFieldPreset = function(reportTypeID, categoryDescriptor, subCategoryDescriptor, itemDescriptor, newPresetStatus, index) {
      setFieldStatus('preset', reportTypeID, categoryDescriptor, subCategoryDescriptor, itemDescriptor, newPresetStatus, index);
    }

    //Save modification status per category for each report type with a key of subCategory and item
    const setFieldModified = function(reportTypeID, categoryDescriptor, subCategoryDescriptor, itemDescriptor, newModifiedStatus, index) {
      setFieldStatus('modified', reportTypeID, categoryDescriptor, subCategoryDescriptor, itemDescriptor, newModifiedStatus, index);
    }

    //Save invalidity status per category for each report type with a key of subCategory and item
    const setFieldInvalid = function(reportTypeID, categoryDescriptor, subCategoryDescriptor, itemDescriptor, newInvalidStatus, index) {
      setFieldStatus('invalid', reportTypeID, categoryDescriptor, subCategoryDescriptor, itemDescriptor, newInvalidStatus, index);
    }

    //Stores all the calculated counts of each status for each category in each report type
    const fieldStatusCounts = ref({
      'preset': {},
      'modified': {},
      'invalid': {},
      'modifiedAndValid': {} //Extra computed set of modified values that are not invalid!
    });

    //Watch status deep, as the instance of the statuses always stays the same, just the keys are modified
    watch([fieldStatuses], ([newStatuses]) => {
      //Compute extra set of modified values that are not invalid
      let computedStatuses = {
        ...newStatuses,
        //Map all levels, picking the items with the correct status in the lowest level and filtering each level to remove empty ones
        'modifiedAndValid': _.mapValues(newStatuses['modified'], (categories, reportTypeID) => {
          return _.mapValues(categories, (subCategories, categoryDescriptor) => {
            return _.mapValues(subCategories, (indizes, subCategoryDescriptor) => {
              return _.mapValues(indizes, (items, index) => {
                return _.pickBy(items, (modifiedStatus, itemDescriptor) => {
                  //Try to get value in invalid with false (valid) as default, if it can't be found
                  if (_.get(newStatuses, ['invalid', reportTypeID, categoryDescriptor, subCategoryDescriptor, index, itemDescriptor], false) === true) {
                    //If it is invalid, exclude it always
                    return false;
                  }

                  //Otherwise include it and just use the modified status
                  return true;
                });
              });
            });
          });
        })
      }

      //Map each status type. Each mapped level is filtered to remove empty counts
      fieldStatusCounts.value = _.mapValues(computedStatuses, (statusObject) => {
        //Map the categories in each report type in the status object
        return _.pickBy(_.mapValues(statusObject, (categories) => {
          //Map the subCategories in each category in the report type
          let categoryCounts = _.pickBy(_.mapValues(categories, (subCategories) => {
            //Map the indizes in each subCategory
            let subCategoryCounts = _.pickBy(_.mapValues(subCategories, (indizes) => {
              //Map the states of the fields in each index
              let indexCounts = _.pickBy(_.mapValues(indizes, (states) => {
                //Return the sum of all field states that are true
                return { count: _.sumBy(Object.values(states), (state) => (state === true) ? 1 : 0) };
              }), (stateCount) => stateCount.count > 0);

              return { counts: indexCounts, total: _.sumBy(Object.values(indexCounts), 'count') };
            }), (indizes) => indizes.total > 0);

            return { counts: subCategoryCounts, total: _.sumBy(Object.values(subCategoryCounts), 'total') };
          }), (subCategories) => subCategories.total > 0);

          return { counts: categoryCounts, total: _.sumBy(Object.values(categoryCounts), 'total') };
        }), (categories) => categories.total > 0);
      });
    }, { deep: true });

    //Helper function to count in the given status object all true values for each level defined in statusCountPath
    const getStatusCountFunction = function(statusCountObject) {
      //Fallback if statusCountObject is invalid to always return null
      if (statusCountObject == null) return function() { return null; }

      return function(reportTypeID, statusCountPath) {
        if (reportTypeID in statusCountObject) {
            //Every level of the path needs count, so add it before every one by mapping it to an array with both elements each and flattening it
            let modifiedPath = _.flatMap(statusCountPath, (pathLevel) => ['counts', pathLevel]);
            let countLevel = _.get(statusCountObject[reportTypeID], modifiedPath, null);

            if (countLevel != null && countLevel.total != null && countLevel.total > 0) {
              return countLevel.total;
            }
          }

        return null;
      }
    }

    //Helper function to get all the keys in the given path that have non-zero counts themselves
    const getNonZeroCountKeysFunction = function(statusCountObject) {
      //Fallback if statusCountObject is invalid to always return null
      if (statusCountObject == null) return function() { return null; }

      return function(reportTypeID, statusCountPath) {
        if (reportTypeID in statusCountObject) {
            //Every level of the path needs count, so add it before every one by mapping it to an array with both elements each and flattening it
            let modifiedPath = _.flatMap(statusCountPath, (pathLevel) => ['counts', pathLevel]);
            let countLevel = _.get(statusCountObject[reportTypeID], modifiedPath, null);

            if (countLevel != null && countLevel.counts != null) {
              //Look for all keys where either the total or (for the last level) the singular count is greater than 0
              return Object.keys(_.pickBy(countLevel.counts, (countObject) => (countObject.total > 0 || countObject.count > 0)));
            }
          }

        return null;
      }
    }

    const getPresetEntryCount = computed(() => {
      return getStatusCountFunction(fieldStatusCounts.value['preset']);
    });

    const getModifiedAndValidEntryCount = computed(() => {
      return getStatusCountFunction(fieldStatusCounts.value['modifiedAndValid']);
    });

    const getInvalidEntryCount = computed(() => {
      return getStatusCountFunction(fieldStatusCounts.value['invalid']);
    });

    //Returns all counts that are either modified or preset
    const getModifiedOrPresetEntryCount = computed(() => { //TODO Never tested!
      let modifiedFunction = getStatusCountFunction(fieldStatusCounts.value['modified']);
      let presetFunction = getStatusCountFunction(fieldStatusCounts.value['preset']);

      return function(...countFunctionArgs) {
        //Call each of the two functions and sum all together, return if greater than 0
        let summedCount = _.sum(modifiedFunction(...countFunctionArgs), presetFunction(...countFunctionArgs));
        if (summedCount > 0) return summedCount;
        return null;
      }
    });

    //Returns all counts that are either modified or preset
    const getModifiedOrPresetEntries = computed(() => {
      let modifiedFunction = getNonZeroCountKeysFunction(fieldStatusCounts.value['modified']);
      let presetFunction = getNonZeroCountKeysFunction(fieldStatusCounts.value['preset']);

      return function(...nonZeroFunctionArgs) {
        //Call each of the two functions and union all the keys that are in either of them
        return _.union(modifiedFunction(...nonZeroFunctionArgs), presetFunction(...nonZeroFunctionArgs));
      }
    });

    const presetReportTypes = computed(() => {
      //Returns all the report type IDs in the correct order
      return _.intersection(loadedReportTypesIDs.value, Object.keys(fieldStatusCounts.value['preset']));
    });

    const modifiedAndValidReportTypes = computed(() => {
      return _.intersection(loadedReportTypesIDs.value, Object.keys(fieldStatusCounts.value['modifiedAndValid']));
    });

    const invalidReportTypes = computed(() => {
      return _.intersection(loadedReportTypesIDs.value, Object.keys(fieldStatusCounts.value['invalid']));
    });

    const isEditingExisting = computed(() => {
      //If any reports are loaded we are in editing mode
      if (loadedReportsFields.value != null && _.filter(Object.keys(loadedReportsFields.value), (type) => type != null).length > 0) return true;
      return false;
    });

    const areAnyFieldsModified = computed(() => {
      //Either modified or invalid prevents navigating away
      let modifiedFieldCount = _.sumBy(Object.values(fieldStatusCounts.value['modified']), 'total');
      let invalidFieldCount = _.sumBy(Object.values(fieldStatusCounts.value['invalid']), 'total');
      return (modifiedFieldCount >= 1 || invalidFieldCount >= 1);
    });

    //Tracks if enough data has been entered to send at least one full report
    const enoughDataHasBeenEntered = computed(() => {
      //Go through each report type and check. The first report that is able to be sent fulfills this function with true as enough data has been entered to send something
      for (let reportTypeId of loadedReportTypesIDs.value) {
        //For first creation of reports, presets are accepted to be enough
        if (loadedReportsFields.value == null || !(reportTypeId in loadedReportsFields.value)) { //Check that this report type has not been loaded and thus is not being edited
          //If it is in preset state, accept it
          if (presetReportTypes.value.includes(reportTypeId)) return true;
          //If we have no presets, but values entered, the value has to be from the report itself for the first creation
          if (modifiedAndValidReportTypes.value.includes(reportTypeId)) return true;
        } else {
          //When editing, we expect at least one modified value (anywhere, even in metadata), otherwise editing is not needed. Horse modification is checked separately
          if (modifiedAndValidReportTypes.value.includes(reportTypeId) || (fieldStatusCounts.value['modifiedAndValid'] != null && UNCATEGORIZED_METADATA_CATEGORY in fieldStatusCounts.value['modifiedAndValid']) || selectedHorseStatusStyle.value === 'modified') return true;
        } //TODO Test that when loaded, modifying metadata is enough!
      }

      return false;
    })

    const getLocalTimeObject = computed(() => {
      return (isoString) => {
        return dayjs((new Date(isoString)));
      }
    });


    const validateAndReportInvalidityAsync = async function(){
      //First check the header fields, if they are all valid and report their validity immediately
      if (headerFormRef.value != null && !headerFormRef.value.reportValidity()) return false;

      //Wait time to ensure all collapsible lists inside the forms are also opened!
      const resolveTimeout = 100;

      //Go through each report type and check its form for validity without reporting it yet
      for (const [reportTypeId, reportTypeFormRef] of Object.entries(reportTypeFormRefs.value)) {
        if (reportTypeFormRef != null && !reportTypeFormRef.checkValidity()) { //This invalidity check automatically opens collapsed list, because they have an invalidity listener for their items!
          //If it is invalid, create a promise to wait for it to become visible
          let formVisiblePromise = new Promise((resolve) => {
            //Mutation observer will listen for change in visibility (display: none)
            const observer = new MutationObserver(() => { 
              observer.disconnect();
              setTimeout(() => resolve(), resolveTimeout);
            });

            try{
              //If it is already visible resolve immediately
              if (getComputedStyle(reportTypeFormRef).display !== 'none') {
                setTimeout(() => resolve(), resolveTimeout);
                return;
              }

              //Otherwise observe for the change
              observer.observe(reportTypeFormRef, { attributes: true });
            } catch {
              //Just continue if an error occurs with the observer, the Promise resolves anyway after a timeout
            }

            //Timeout as a fallback to always resolve after a set amount
            setTimeout(() => {
              if (observer != null) observer.disconnect();
              resolve();
            }, 2000);
          });

          //Navigate to the first invalid entry and open the invalid category (automatically in its event), so reportvalidity works correctly for hidden fields
          currentReportType.value = reportTypeId;

          //Then once it is fully visible report the validity and save its result
          let valid = await formVisiblePromise.then(() => reportTypeFormRef.reportValidity());

          //On the first invalid form return false, no need to check more. Stay at the invalid field!
          if (!valid) return false;
        }
      }

      //If no invalid forms were found, return true
      return true;     
    }

    const submitReport = async function() {
      currentlySubmitting.value = true;

      //Validate form with constraints that are set inside the entry items and show the result with reportValidity (returns result of checkValidity)
      if (!enoughDataHasBeenEntered.value) {
        currentlySubmitting.value = false;
        return;
      }

      if (!(await validateAndReportInvalidityAsync())) {
        currentlySubmitting.value = false;
        return;
      }

      //TODO Use report util methods to create reusable report objects?

      //TODO We could maybe add same report fields to connections, so if afterwards two different reports are connected, user can choose which date to use and set that at the connection. But how does it behave on edit of multiple with different ones. Will be overwritten everywhere!
      //TODO Name it "Untersuchungsdokument" not "Bericht" to differentiate it and make it seem like a smaller unit!

      let reportsForSubmit = [];

      //Get the report timestamp once for routing to it in the end on success
      let reportTimestamp = getReportProperty.value(REPORT_PROPERTY_MAPPINGS['timestamp']);

      //Convert the properties and metadata to arrays of key and value for comparison
      let modifiedPropertyPairs = _.toPairs(modifiedReportProperties.value);
      let modifiedMetadataPairs = _.toPairs(modifiedReportMetadata.value);

      //Remove all properties and metadata that are in loaded or preset and save it as an object again to use for editing existing reports
      let nonPresetProperties = _.fromPairs(_.differenceWith(modifiedPropertyPairs, _.toPairs(loadedReportProperties.value), _.toPairs(presetReportProperties.value), _.isEqual));
      let nonPresetMetadata = _.fromPairs(_.differenceWith(modifiedMetadataPairs, _.toPairs(loadedReportMetadata.value), _.toPairs(presetReportMetadata.value), _.isEqual));

      let personalHorseInfoId = getReportProperty.value(REPORT_PROPERTY_MAPPINGS['animal']);
      let horseId = store.getters['horses/getNewestHorseIdForPersonalInfoId'](personalHorseInfoId);

      //TODO Set the horse dependency on upload separately!! When freshly created horse, remember dependency!
      //Automatically create horse if it didn't exist yet for that personal horse info
      if (personalHorseInfoId != null && horseId == null) {
        //TODO Implement and test with a horse that has none set yet
      }

      //TODO Add property and metadata files universally to a new file object for the connection!

      for (const reportTypeID of loadedReportTypesIDs.value) {
        //Only take the values as an array, the keys were just helpers and are embedded in the fields as well
        let reportFieldArray = flattenHierarchicalObject(modifiedReportsFields.value[reportTypeID]);
        let loadedReportFieldArray = flattenHierarchicalObject(loadedReportsFields.value[reportTypeID]);
        let presetReportFieldArray = flattenHierarchicalObject(presetReportsFields.value[reportTypeID]);
        
        let currentReportForSubmit = {};

        //Add id if it was an existing report
        if (reportTypeID in loadedReportIDs.value) currentReportForSubmit['id'] = loadedReportIDs.value[reportTypeID];

        //If this is an existing report that has been loaded, exclude already matching fields
        if (reportTypeID in loadedReportsFields.value) { //Always send at least some fields like timestamp, to create a new version of all edited reports for a new valid connection and all having the same metadata!
          _.assign(currentReportForSubmit, nonPresetMetadata, nonPresetProperties); //TODO Move Metadata to the connection, load metadata if given by reports or the connection prop. Always load all reports in a connection. Update or create based on existence.
          
          //Set the internal horse id for each report
          currentReportForSubmit['horse'] = horseId; //TODO Check if it is the same and didn't change, if so set it to undefined

          //Set fields that are removed as fallback
          currentReportForSubmit['control_examination'] = false;
          
          //TODO Remove duplicates of index if they are null! Leave the non-null ones for each duplicate!
          currentReportForSubmit['fields'] = _.cloneDeep(_.differenceWith(reportFieldArray, loadedReportFieldArray, presetReportFieldArray, _.isEqual)); //TODO How to detect changes here with index?!
        } else { //Otherwise just set all
          //Only set type if it is a new report
          //Get internal type id for submitting and only continue, if it is valid
          let reportTypeInternalID = _.get(reportTypesByKey.value, [reportTypeID, 'type', 'id']);
          if (reportTypeInternalID == null) return;

          currentReportForSubmit['type'] = parseInt(reportTypeInternalID);

          //Assign properties and metadata as root attributes in the order from preset, over loaded to modified, to set all, and latter overwriting the earlier
          _.assign(currentReportForSubmit, presetReportMetadata.value, presetReportProperties.value, loadedReportMetadata.value, loadedReportProperties.value, modifiedReportMetadata.value, modifiedReportProperties.value);

          //Set the internal horse id for each report
          currentReportForSubmit['horse'] = horseId;

          //Set fields that are removed as fallback
          currentReportForSubmit['control_examination'] = false;

          //Same order for the fields, using union to only set each key once. It always choses from the first one it finds, so they are defined here in reverse
          currentReportForSubmit['fields'] = _.cloneDeep(_.unionBy(reportFieldArray, loadedReportFieldArray, presetReportFieldArray, 'key'));

          //Do not submit new reports that are empty!
          if (!(Array.isArray(currentReportForSubmit['fields'])) || !(currentReportForSubmit['fields'].length > 0)) continue;
        }

        let currentReportFiles = {};

        _.forEach(currentReportForSubmit['fields'], (field, fieldIndex) => {
          //Loop through all possible variations of file properties and add them, if found
          for (let filePropertyIndicator of ['file', 'files']) {
            if (field[filePropertyIndicator] != null) {
              currentReportFiles[`fields[${fieldIndex}].${filePropertyIndicator}`] = field[filePropertyIndicator];
              _.unset(field, filePropertyIndicator);
              //Set for each file newly, might override the previous one
              field.uploaded_at = Date.now(); //TODO Move this to the server
            }
          }
        });
                    

        //Only send report, if anything has been set or modified, fields or any properties! Check for the first valid property
        let isValidReport = _.some(currentReportForSubmit, (value, key) => {
          //If it is fields, return true if it is a valid array with at least one entry!
          if (key === 'fields') {
            if (Array.isArray(value) && value.length > 0) return true;
          } else { //If it is any other property it must be something other than undefined (null counts as removal and is valid!)
            if (value !== undefined) return true;
          }

          return false;
        });
        if (isValidReport) reportsForSubmit.push({report: currentReportForSubmit, files: currentReportFiles}); //TODO Add horse dependency if it is newly created!
      }

      //TODO Check how it behaves when a file is unmodified and then if it is modified //TODO Also check when multiple files are allowed when we have an unmodified and a modified one 

      //Start the upload of each report and map their results into an array of uploadPromises
      let uploadPromises = _.map(reportsForSubmit, (reportConfig) => {
        if (reportConfig != null && reportConfig.report != null) {
          //It is an existing report, update it
          if (reportConfig.report['id'] != null) {
            return store.dispatch('reports/updateReport', {id: reportConfig.report['id'], ...reportConfig});
          } else {
            return store.dispatch('reports/createNewReport', {...reportConfig});
          }
        }
      });

      Promise.all(uploadPromises)
      .catch((error) => {
        throw error; //TODO Rethrow meaningful error here. Can't continue, if one upload failed. How should we recover from that state? Rethrow to not continue?
      })
      .then((uploadInfoArray) => {
        if (uploadInfoArray != null && Array.isArray(uploadInfoArray)) {
          //If more than one report uploaded, connect it
          if (uploadInfoArray.length > 1) {
            let uploadingKeys = _.map(uploadInfoArray, 'key'); //TODO For update just take ID for already existing ones that are not updated and the keys for all the others and call updateReportConnection with all those changes. Or was there the option to jsut send new ones and remove old ones? Dependecny only has to include uploading ones!
            return store.dispatch('reports/connectReports', { reports: uploadingKeys, reportDependencyArray: uploadingKeys }).then((uploadInfo) => {
              let completion = uploadInfo.completionPromise;
              if (completion != null) completion.then(() => {
                store.dispatch('reports/fetchReportIndex');
              });
              return { connectionID: _.get(uploadInfo, 'key') };
            });
          } else { //Otherwise just forward to the uploading report with the key!
            let completion = _.get(uploadInfoArray, [0, 'completionPromise']);
            if (completion != null) completion.then(() => {
              store.dispatch('reports/fetchReportIndex');
            });
            return { singleID: _.get(uploadInfoArray, [0, 'key']) };
          }
        }
        //TODO Move metadata to separate model and update comments too, that it is not saved in the connection! Add metadata to connection through relation? Instead of to reports?
      }).then(({connectionID, singleID}) => {
        if (props.previewModalMode) {
          modalController.dismiss();
        } else {
          //Go to uploading reports (from connection) page or tracking page if index is null
          if (connectionID != null) {
            router.replace(`/health/analysis/reports/${connectionID}`);
          } else if (singleID != null) {
            router.replace(`/health/analysis/report/${singleID}`);
          }
        }
      })
      .then(() => {
        //Set the currently selected day and month to the one of this report, to immediately scroll to the upload to see progress
        let localReportTime = getLocalTimeObject.value(reportTimestamp); //FIXME Only continue if date is valid? Try what happens with invalid date again! Otherwise go to today or let the store handle the error and go to today!
        store.dispatch('reports/updateSelectedMonth', localReportTime.format('MM.YYYY'));
        store.dispatch('reports/updateSelectedDay', localReportTime.format('DD.MM.YYYY'));
        store.dispatch('reports/updateSelectedTimespanDay', null);
        //TODO Reset filters to show the currently uploading one!!!
      })
      .catch((error) => {
        console.error(error); //TODO Can't continue, if one upload failed. How should we recover from that state? Rethrow to not continue?
      })
      .finally(() => {
        currentlySubmitting.value = false;
      });

      /*
        //TODO Implement a warning on upload everywhere in the app, if the upload queue has the flag of in memory driver set!
        .then(({index, persistent}) => {
          //If not persistent, show a warning to the user to not close the app
          if (!persistent) {
            return toastController.create(
              {
                message: i18n.$t('report.create.errors.upload_queue_not_persistent'),
                position: 'top',
                duration: 0,
                color: 'warning',
                buttons: [
                  {
                    text: i18n.$t('default_interaction.close'),
                    role: 'cancel'
                  }
                ]
              }
            ).then((toast) => {
              toast.present();
              return index;
            })
            .catch(() => {
              return index;
            });
          } else {
            return Promise.resolve(index);
          }
        })
        */
    }

    const fetchReport = function(id) {
      return store.dispatch('reports/fetchReport', id);
    }

    const processReportPromises = async function(reportPromiseArray) { //TODO Try setting the attached type with different values for the field types to see which one is preferred, Try setting the attached type with an ID that can't be loaded to see the fallback behaviour
      //TODO Should the reports be sorted? By the type?
      return Promise.all(reportPromiseArray)
        .then((reportArray) => _.compact(_.map(reportArray, (report) => { //First map it so it only includes the data! All currently uploading ones are filtered out to prevent editing them!! - As a fallback, should never route here while uploading!
          if (report.uploadStatus != null) return null;
          return report.data;
        })))
        .then((reportArray) => {
          let firstReport = _.first(reportArray);
          //Take properties and metadata from first report as they should be the same for all
          if (firstReport != null) {
            //Properties are the values defined in the mapping
            loadedReportProperties.value = _.pick(firstReport, Object.values(REPORT_PROPERTY_MAPPINGS));
            loadedReportMetadata.value = _.get(firstReport, METADATA_PROPERTY); ///TODO Get from the report in metadata or some other way. Also down for every other report. Will the property called differently?
          }


          //Map all reports to only include the fields of each one and convert to object with type identifiers as the keys
          let reportsByTypeIDs = _.keyBy(reportArray, (reportObject) => {
            let reportType = _.get(reportObject, ['type'], null);
            //If it is an object try to get ID
            if (_.isObject(reportType)) return _.get(reportType, ['id'], null);
            //Otherwise just return the type itself, as it is a primitive type
            return reportType; //TODO Maybed use name or slugify for loading newest version of this type. Maybe only if requested? Types of fields can change in between versions!
          });

          let newReportsFields = {};
          let newReportsTypes = {};
          let newReportIDs = {};

          let rejectedReports = [];

          for (let [reportTypeID, report] of Object.entries(reportsByTypeIDs)) {
            if (report != null) {
              //Check that the properties and metadata match the one of the first/existing one, otherwise don't include this one and set a warning - Prevents inconsistencies with different metadata
              if (!(_.isEqual(loadedReportProperties.value, _.pick(report, Object.values(REPORT_PROPERTY_MAPPINGS)))) ||
                  !(_.isEqual(loadedReportMetadata.value, _.get(report, METADATA_PROPERTY)))) {
                    rejectedReports.push({id: report.id, type: reportTypeID, descriptor: _.get(report, ['type', 'descriptor'], null)});
                    continue;
              }

              //TODO Reject edits of reports that are connected but not all reports of that connection are being edited? Because properties and metadata might become inconsistent. If user still wants to save after warning -> First remove from link! Tell user!

              let fields = _.get(report, FIELDS_PROPERTY);
              let fieldTypes;
              //Transform fields to a hierarchical object in the same format as report definition
              if (fields != null) {
                [fields, fieldTypes] = reportFieldsToHierarchicalObject(fields, false, true);

                //Get the type definition and merge with the types of the fields. More specific of type definition overwrites fieldTypes
                let type = _.get(report, 'type', {});
                let definition = _.get(report, 'type.definition');

                //If the report contains a valid definition, use that to set the definitions metadata to each field
                if (definition != null) {
                  definition = typeDefinitionToHierarchicalObject(definition);
                  //Apply properties of the definition to the fieldTypes to add additional metadata and always use the type from the definition
                  fieldTypes = _.merge({}, fieldTypes, definition);
                }

                //Merge existing information about the type and set the definition again - Type might be empty, only definition could be set
                newReportsTypes[reportTypeID] = {...type, definition: fieldTypes};

                newReportsFields[reportTypeID] = fields;

                newReportIDs[reportTypeID] = report.id;
              }
            }
          }

          //TODO Show error toasts with rejected reports!

          //Shallow merge the report types contained in the loaded ones to potential existing ones first. Those will be used in the loading procedure to use as fallback 
          loadedReportTypes.value = _.assign({}, loadedReportTypes.value, newReportsTypes);
          //Shallow merge the newly loaded reports to potential existing ones. Adding the loaded reports triggers loading of the whole report types from the API.
          loadedReportsFields.value = _.assign({}, loadedReportsFields.value, newReportsFields);
          //Shallow merge the newly loaded reportIDs to potential existing ones.
          loadedReportIDs.value = _.assign({}, loadedReportIDs.value, newReportIDs);
        })
        .catch((error) => {
          apiErrorToast(i18n, error, true) //TODO Test that it gets caught
            .then(() => router.go(-1));
        });
    }

    watch(() => props.id, (newId) => {
      if (newId != null) {
        loadingReports.value = true;
        processReportPromises([fetchReport(newId)])
          .finally(() => loadingReports.value = false);
      }
    }, { immediate: true });

    //TODO Should we route back or show an error, if no reports get loaded, even though preocessReportPromise got called?

    watch([() => props.connection_id, () => store.getters['reports/getReportIDsInConnection']], ([newConnectionId, newReportGetter]) => {
      if (newConnectionId != null) {
        loadingReports.value = true;
        //Get all reports in this connection (updates automatically as the getter is reactive). //FIXME Reactivity does not work here
        let connections = newReportGetter(newConnectionId);
        //If it has an uploading status and it is not finished, go back with an error, as the reports can't be edited
        if (connections != null && connections.status != null && _.get(connections, ['status', 'finishedID']) == null) {
          return localErrorToast(i18n, i18n.$t('report.create.errors.edit_uploading'))
                  .then(() => router.go(-1));
        }
        //Fetch all reports by mapping each report id to the promise of fetchReport and give the result to the process function.
        processReportPromises(_.map(connections.reports, (reportId) => fetchReport(reportId)))
          .finally(() => loadingReports.value = false);
      }
    }, { immediate: true });

    //Called from onMounted and watcher. Only called once!
    const fetchReportTypes = function(typeIdentifierArray){
      //Load report types only, if they are valid IDs
      if (Array.isArray(typeIdentifierArray) && typeIdentifierArray.length > 0 && !(loadingReportTypes.value)) {
        loadingReportTypes.value = true;

        //Get all report types from the index with the requested identifiers
        const typeIndexObject = store.getters['reports/getReportTypesByIdentifiers'](typeIdentifierArray);
        //Load all in parallel and save the results in an array of promises
        let fetchReportTypePromises = _.map(typeIdentifierArray, (identifier) => {
          return new Promise((resolve, reject) => {
            //If it has been already supplied we do not need to load it again, so return the preLoadedTypeDefintion
            if (props.preLoadedTypes != null && identifier in props.preLoadedTypes) {
              return resolve(props.preLoadedTypes[identifier]);
            }
            //Go through in order of the requested identifiers and try to load if in the index
            if (identifier in typeIndexObject && typeIndexObject[identifier].id != null) {
              return store.dispatch('reports/fetchReportType', typeIndexObject[identifier].id)
                .then((result) => resolve(result))
                .catch((error) => reject(error));
            }
            //If not in the index throw an error for this one
            return reject('Not found in index');
          });
        });
        
        //Wait until all are finished
        Promise.allSettled(fetchReportTypePromises)
        .then((reportTypeLoadStatus) => {
          //First add the reportIDs that were supposed to be loaded to the results
          _.zipWith(reportTypeLoadStatus, typeIdentifierArray, (statusObject, typeIdentifier) => statusObject.id = typeIdentifier);
          //Separate fulfilled and rejected promises to handle each separately. Unfulfilled, but with fallback are also considered fetched here for now.
          let [fetchedTypes, failedTypes] = _.partition(reportTypeLoadStatus, (statusObject) => (statusObject.status === 'fulfilled' || statusObject.id in loadedReportTypes.value));
          //Filter fallback types to report to user
          let fetchedFallbackTypes = _.filter(fetchedTypes, (statusObject) => statusObject.status !== 'fulfilled');
          
          //If none of the reports could be loaded, throw error to leave
          if (fetchedTypes.length <= 0) throw (i18n.$t('report.create.errors.no_types_loaded') || 'None of the protocols could be loaded'); //TODO Test: Make just one fail and then all fail
          //If just some of the types failed to fetch, notify user and continue
          try {
            if (failedTypes.length > 0 || fetchedFallbackTypes.length > 0) {
              let failedCounts = [];
              if (failedTypes.length > 0) {
                console.error('Failed loading types: ', _.map(failedTypes, (statusObject) => `${statusObject.id}: ${statusObject.reason}`)); //Log all the reasons for failed fetches
                failedCounts.push(`${ i18n.$t('report.create.errors.error_count') }: ${ failedTypes.length }`);
              }
              if (fetchedFallbackTypes.length > 0) {
                console.error('Failed loading types, using fallback: ', _.map(fetchedFallbackTypes, (statusObject) => `${statusObject.id}: ${statusObject.reason}`)); //Log all the reasons for failed fetches
                failedCounts.push(`${ i18n.$t('report.create.errors.fallback_count') }: ${ fetchedFallbackTypes.length }`);
              }
              //Show an error message for the user with the number of missing report types
              localErrorToast(i18n, i18n.$t('report.create.errors.load_type'), failedCounts.join('\n'));
            }

            
          } catch {
            //Because it is not so important that some protocols are missing, we can ignore errors
          }

          //Set id and value of the successful fetches as the loaded types adding the information to the existing types (fallback overwritten with more specific values)! Removes all falsey values just in case the fallback disappeared!
          let newReportTypes = _.compact(_.map(fetchedTypes, (statusObject) => {
            if (statusObject.status === 'fulfilled' || loadedReportTypes.value[statusObject.id] != null) {
              let fetchedType = statusObject.value;
              let definition = _.get(fetchedType, 'definition');

              //If the report type contains a valid definition convert it to hierarchical format for merging
              if (definition != null) {
                //Create a shallow copy with the definition being mapped
                fetchedType = {
                  ...fetchedType,
                  'definition': typeDefinitionToHierarchicalObject(definition)
                }
              }

              return {
                id: statusObject.id,
                //Apply properties of the fetched type definition to the existing loaded report types to add additional metadata and always use the type from the more specific full definition
                type: _.merge({}, loadedReportTypes.value[statusObject.id], fetchedType)
              }
            }
          }));

          //Shallow merge the newly loaded report types to potential existing ones using union. Only new ones get added, existing ones with same ID stay the same. Preserves the order of the report types!
          reportTypes.value = _.unionBy(reportTypes.value, newReportTypes, 'id');
        })
        .then(() => {
          loadingReportTypes.value = false;
        })
        .catch((error) => {
          console.error(error);
          localErrorToast(i18n, error)
            .then(() => {
              if (props.previewModalMode) {
                modalController.dismiss();
              } else {
                router.go(-1);
              }
            });
        });
      }
    }

    //Fetch the report type definitions, as soon as we know which ones to load
    watch(reportTypeIds, (newTypeIds) => fetchReportTypes(newTypeIds), { immediate: true });

    const NON_VETERINARIAN_DEFAULT_LOCATION = 'stable';

    //For setting the default, set the preset immediately and also set in FormField via a computed property
    watch(isVeterinarian, (vetStatus) => {
       if (vetStatus === false) {
        setPresetReportProperty(REPORT_PROPERTY_MAPPINGS['location'], NON_VETERINARIAN_DEFAULT_LOCATION);
      }
    }, { immediate: true });

    const locationPresetSetting = computed(() => {
      if (isVeterinarian.value) {
        const locationDefaultSetting = 'clinic'; //TODO Get from settings model
        return locationDefaultSetting || undefined;
      } else {
        return NON_VETERINARIAN_DEFAULT_LOCATION;
      }
    });

    //Query to check for big screen. Separate from the grid in css!
    const bigScreenQuery = window.matchMedia("(min-width: 750px)");

    //Set initially if it matches, and update on change
    const isBigScreen = ref(bigScreenQuery.matches);
    bigScreenQuery.addEventListener('change', (query) => {
      isBigScreen.value = query.matches;
    });

    //Query to check for big enough screen to have 2-3 elements next to each other.
    const widePhoneScreenQuery = window.matchMedia("(min-width: 300px)");

    //Set initially if it matches, and update on change
    const isWidePhoneScreen = ref(widePhoneScreenQuery.matches);
    widePhoneScreenQuery.addEventListener('change', (query) => {
      isWidePhoneScreen.value = query.matches;
    });

    const closeModal = function(){
      modalController.dismiss();
    }

    return {
      i18n,
      isVeterinarian,
      now,
      tomorrow,
      locationValues,
      headerFormRef,
      setReportTypeFormRef,
      saveButton,
      reportTypes,
      loading,
      CATEGORY_SEPARATOR,
      UNCATEGORIZED_CATEGORY,
      UNCATEGORIZED_METADATA_CATEGORY,
      ADDITIONAL_COMMENT_FIELD,
      buildKey,
      getSortedEntriesBySortOrder,
      getPresetRepeatedSubCategories,
      getReportProperty,
      getReportMetadata,
      getReportField,
      setReportProperty,
      setReportMetadata,
      setReportField,
      setPresetReportProperty,
      setPresetReportMetadata,
      setPresetReportField,
      removeIndizesFromSubCategory,
      availableReportTypes,
      currentReportType,
      reportTypesByKey,
      REPORT_PROPERTY_MAPPINGS,
      availableHorses,
      selectedHorseStatusStyle,
      mapHorseToPersonalHorseInfoId,
      locationPresetSetting,
      currentlySubmitting,
      getLocalizedReportString, 
      getLocalizedReportStringArray,
      reportEntryRefs,
      handleSpacebar,
      toggleAdditionalComment,
      isAdditionalCommentEnabled,
      isBigScreen,
      isWidePhoneScreen,
      setFieldPreset,
      setFieldModified,
      setFieldInvalid,
      getPresetEntryCount,
      getModifiedAndValidEntryCount,
      getInvalidEntryCount,
      getModifiedOrPresetEntries,
      getModifiedOrPresetEntryCount,
      presetReportTypes,
      modifiedAndValidReportTypes,
      areAnyFieldsModified,
      invalidReportTypes,
      isEditingExisting,
      enoughDataHasBeenEntered,
      submitReport,
      closeModal,
      chevronForward,
      save,
      addCircleOutline,
      faCalendarDay,
      faHouseMedicalFlag,
      faClipboardList,
      faVoicemail
    };
  }
}
</script>

<style scoped>
.ios #title {
  font-size: clamp(.9em, 4vw, 17px);
  font-weight: 600;
}

#title {
  font-size: clamp(.9em, 4vw, 20px);
  font-weight: 500;
  letter-spacing: 0.0125em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
}

/*Query to check for big screen. Separate from js! */
@media (min-width: 700px) {
  .header-container {
    grid-template-columns: 5fr 6fr;
  }

  .ios .header-container {
    grid-template-columns: 1fr 1fr;
  }

  .header-container > *:not(:last-child) {
    margin-right: 0px;
  }
}

.header-card ion-card-content {
  padding: 0px;
}

.voice-note-input {
  --padding-start: 41.5px;
}

.animal-select {
  --padding-start: 10px;
  --custom-padding-start: 5px;
  --custom-avatar-icon-size: 1.2em;
  --custom-avatar-size: 46px;
}

.ios ion-card {
  margin-bottom: 0px;
  margin-top: 12px;
}

.ios ion-card-title {
  font-size: 1.3em;
}

.ios ion-card-header {
  font-size: 1em;
}

ion-item, ion-list {
  --background: var(--ion-card-background, #fff);
}

/* Merge cards by setting no radius and no margin, so one card can be a sticky header, appearing like only part of the card is sticky */
.protocol-selection-header {
  margin-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.protocol-selection-overflow-bounds {
  overflow: hidden;

  position: sticky;
  top: 0px;
  z-index: 1000;
}

.protocol-selection-container {
  margin-top: 0px!important;
  margin-bottom: 0px;
  border-radius: 0px;

  border-bottom: 6px solid var(--ion-color-medium-light);
}

.protocol-selection {
  margin-left: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 0px;
  --width-offset: 16px;
  --background: var(--ion-card-background, #fff);
}

.ios .protocol-selection {
  --width-offset: 20px;
}

.protocol-content-container {
  margin-top: 0px!important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.protocol-content {
  padding: 0px;
}

.protocol-content-list {
  padding: 0px;
}

.protocol-hidden {
  display: none;
}

#over-scroll {
  width: 100%;
  height: 100px;
}

.category-header {
  font-size: 20px;
  --color: var(--ion-color-primary-text);
  color: var(--color);
  background-color: var(--ion-card-background, #fff);
  --background: var(--ion-card-background, #fff);
  --custom-padding-start: 10px;
  --custom-item-margin: 10px;
}

:deep(ion-item-divider) {
  --padding-start: 10px;
  --padding-top: 5px;
  --padding-bottom: 5px;
  --inner-padding-end: 10px;
}

:deep(.subcategory-divider) {
  --color: var(--ion-color-dark);
  --background: rgba(var(--ion-color-secondary-rgb), 0.2);
  font-size: 0.75em;
  border-width: 0 0 1px 1px;
  border-style: solid;
  border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
}

.form-item:not(.additional-comment-report) {
  --border-width: 0 0 1px 1px;
}

.category-header:not(:first-child) {
  --collapsible-header-border-width: 1px 0px 1px 0px;
}

:deep(ion-item-divider ion-label) {
  white-space: normal!important;
  text-overflow: unset;
  margin-top: 0px;
  margin-bottom: 0px;

  pointer-events: none;
  /* Disable select on texts */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.ios .additional-comment-report-divider {
  font-size: 0.9em;
}

.additional-comment-report-divider {
  --background: rgba(var(--ion-color-secondary-rgb), 0.4);
  --color: var(--ion-color-dark);
}
.additional-comment-report-divider > ion-button {
  --color: var(--ion-color-secondary-contrast);
  --background-activated: var(--ion-color-secondary-contrast);
  --background-focused: var(--ion-color-secondary-contrast);
  --background-hover: var(--ion-color-secondary-contrast);
}

.add-comment-button {
  --color: var(--ion-color-primary-text);
  --padding-start: 0px;
  --padding-end: 0px;
  --background-focused-opacity: 0.25;
}

.add-comment-button:focus {
  outline: none;
}

/* Hide non-enabled additional-comments */
.expandable-additional-comments:not(.enabled) ion-item {
  display: none;
}

/* Hide enable button, when it is enabled */
.expandable-additional-comments.enabled > ion-item-divider > ion-button {
  display: none;
}

.upload-progress {
  position: absolute;
  bottom: 0px;
  z-index: 9000;
  height: 6px;
}

ion-fab {
  z-index: 8000;
}

.save-button.submitting {
  opacity: 1!important;
}

.save-button ion-spinner {
  color: var(--ion-color-contrast);
}

.animal-select.invalid ion-label {
  color: var(--ion-color-danger)!important;
}

.animal-select.modified ion-label {
  color: var(--ion-color-success-shade)!important;
}

.animal-select.preset ion-label {
  color: var(--ion-color-primary-text)!important;
}

ion-backdrop {
  opacity: 0.4;
}

ion-backdrop.block-all {
  z-index: 2000;
}

.loading-spinner {
  z-index: 2500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--ion-color-light);
  padding: 15px;
  border-radius: 10px;
}
</style>
